import { Injectable, NgZone } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable, timer } from "rxjs";
import { retry, retryWhen, delay } from 'rxjs/operators';

@Injectable()
export class SSEService {
  private eventSource: EventSource;
  private reconnectAttempt: number = 0;
  private readonly MAX_RECONNECT_ATTEMPTS: number = 5;
  private readonly INITIAL_RECONNECT_DELAY: number = 1000; // 1 second
  private readonly MAX_RECONNECT_DELAY: number = 30000; // 30 seconds

  constructor(private readonly zone: NgZone) { }

  observeMessages(): Observable<string> {
    return new Observable<string>(observer => {
      const connect = () => {
        try {
          this.eventSource = this.getEventSource();

          this.eventSource.onopen = (ev) => {
            if (!environment.production) console.log('Connection to server opened.', ev);
            this.reconnectAttempt = 0; // Reset reconnect attempt counter on successful connection
          };

          this.eventSource.onerror = (ev) => {
            const error = ev as Event;
            if (!environment.production) console.log('EventSource failed.', error);

            // Close the current connection
            this.eventSource.close();

            // Calculate exponential backoff delay
            const backoffDelay = Math.min(
              this.INITIAL_RECONNECT_DELAY * Math.pow(2, this.reconnectAttempt),
              this.MAX_RECONNECT_DELAY
            );

            if (this.reconnectAttempt < this.MAX_RECONNECT_ATTEMPTS) {
              if (!environment.production) console.log(`Reconnecting in ${backoffDelay}ms... (Attempt ${this.reconnectAttempt + 1}/${this.MAX_RECONNECT_ATTEMPTS})`);
              
              this.reconnectAttempt++;
              setTimeout(() => {
                this.zone.run(() => {
                  connect();
                });
              }, backoffDelay);
            } else {
              if (!environment.production) console.log('Max reconnection attempts reached.');
              observer.error(new Error('Max reconnection attempts reached'));
            }
          };

          this.eventSource.addEventListener('message', (evt: MessageEvent) => {
            if (!environment.production) console.log("sse", String(evt.data));
            this.zone.run(() => {
              observer.next(evt.data);
            });
          });

        } catch (error) {
          if (!environment.production) console.error('Error creating EventSource:', error);
          observer.error(error);
        }
      };

      // Initial connection
      connect();

      // Cleanup on unsubscribe
      return () => {
        if (this.eventSource) {
          this.eventSource.close();
          this.eventSource = null;
        }
      };
    });
  }

  private getEventSource(): EventSource {
    let userSession = JSON.parse(localStorage.getItem('currentUser'));
    if (!userSession || !userSession.accessToken) {
      throw new Error('No valid user session found');
    }

    let url: string = `${environment.sseUrl}/stream?accessToken=${userSession.accessToken}`;
    
    if (this.eventSource && this.eventSource.readyState === EventSource.OPEN) {
      return this.eventSource;
    } else {
      if (this.eventSource) {
        this.eventSource.close();
      }
      this.eventSource = new EventSource(url);
      return this.eventSource;
    }
  }

  logout(): void {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
      this.reconnectAttempt = 0;
      if (!environment.production) console.log('SSE connection closed.');
    }
  }

  isConnected(): boolean {
    return this.eventSource !== null &&
      this.eventSource !== undefined &&
      this.eventSource.readyState === EventSource.OPEN;
  }

  getConnectionStatus(): 'CONNECTING' | 'OPEN' | 'CLOSED' | 'NOT_INITIALIZED' {
    if (!this.eventSource) return 'NOT_INITIALIZED';
    switch (this.eventSource.readyState) {
      case EventSource.CONNECTING: return 'CONNECTING';
      case EventSource.OPEN: return 'OPEN';
      case EventSource.CLOSED: return 'CLOSED';
      default: return 'NOT_INITIALIZED';
    }
  }
}